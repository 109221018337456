<template>
  <div class="video-box" :style="`width: ${width}px; height: ${height-100}px`" ref="videBox">
    <div :id="`yinshiVideoId${index}`" v-show="template != 'pcRec'" class="video-mian"></div>
    <div :id="`yinshiVideoId${index}back`" v-show="template == 'pcRec'" class="video-mian"></div>
    <div class="btn">
      <el-button type="text" style="color: aliceblue !important;font-size: .202083rem;" @click="changeMonitor">直播
      </el-button>
      <el-button type="text" style="color: aliceblue !important;font-size: .202083rem;" @click="focus">聚焦
      </el-button>
      <el-button type="text" style="color: aliceblue !important;font-size: .202083rem;" @click="changePlayback">回放
      </el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EZUIKit from 'ezuikit-js';

export default {
  name: "YinshiVideo",
  props: ['index', 'url', 'width', 'height', 'channel'],
  data() {
    return {
      accessToken: '',
      player: null,
      playerBack: null,
      urlfooter: '1.hd.live',
      template: 'theme',
     
    }
  },
  watch: {
    url: {
      handler(val) {
        this.getToken()
        this.urlfooter = this.channel + '.hd.live' || '1.hd.live'
      },
      
    },
    
  },
  mounted() {
    this.getToken()
    this.urlfooter = this.channel + '.hd.live' || '1.hd.live'
  },
  methods: {
    //登录萤石云获取token
    getToken() {
    
      axios.post(
          "https://open.ys7.com/api/lapp/token/get?Content-Typ=application/x-www-form-urlencoded&appKey=320635483646453daf3ceeae3650c1f6&appSecret=b0f9a01bd01651483181d5e706863618"
      )
          .then((res) => {
            if (res.data.data) {
              let accessToken = res.data.data.accessToken;
              this.getVidoUrl(accessToken)

            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getVidoUrl(accessToken) {
   
      let url = ''
      //获取地址
      axios
          .post(
              "https://open.ys7.com/api/lapp/v2/live/address/get?Content-Typ=application/x-www-form-urlencoded&accessToken=" + accessToken + "&deviceSerial=" + this.url + "&channelNo=" + this.channel
          )
          .then((res) => {
            console.log('open.ys7', res)
            if (res.data && res.data.data) {
              url = res.data.data.url
             
              this.$nextTick(() => {
              
                this.playVideo(accessToken, url);
              });
            } else {
              this.template = 'theme'
              url = "ezopen://open.ys7.com/" + this.url + "/" + this.urlfooter
              this.$nextTick(() => {
                this.playVideo(accessToken, url);
              });
            }
          })
          .catch((err) => {
            console.log('err', err);
            this.template = 'theme'
            url = "ezopen://open.ys7.com/" + this.url + "/" + this.urlfooter
            this.$nextTick(() => {
              this.playVideo(accessToken, url);
            });
          });
    },
    playVideo(accessToken, url) {
      let that = this;
      console.log(that.template, this.urlfooter, "你还呀");
     
      // this.playOptions.url = `ezopen://open.ys7.com/${this.url}/1.hd.live`;
      // this.playOptions.id = `yinshiVideoId${this.index}`;
      // this.playOptions.accessToken = accessToken;
      if (this.template === 'pcRec') {
        this.destoryEZU()
        this.playerBack = new EZUIKit.EZUIKitPlayer({
          id: `yinshiVideoId${this.index}back`, // 视频容器ID
          accessToken: accessToken,
          //autoplay: true,
          url: url,
          template: this.template, // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
          plugin: ["talk"],
          startTalk: () => this.playr.startTalk(),
          stopTalk: () => this.playr.stopTalk(), // 加载插件，talk-对讲
          header: ["zoom"],
          width: this.width - 10,
          height: this.height - 65,
        });
      } else {
        this.destoryEZU()
        this.player = new EZUIKit.EZUIKitPlayer({
          id: `yinshiVideoId${this.index}`, // 视频容器ID
          accessToken: accessToken,
          //autoplay: true,
          url: url,
          template: this.template, // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
          plugin: ["talk"],
          startTalk: () => this.playr.startTalk(),
          stopTalk: () => this.playr.stopTalk(), // 加载插件，talk-对讲
          header: ["zoom"],
          width: this.width - 40,
          height: this.height - 115,
        });
      
      }
    },

    //直播
    changeMonitor() {
      this.template = 'theme'
      this.urlfooter = this.channel + '.hd.live' || '1.hd.live'
      this.destoryEZU()
      this.getToken()
    },
    //聚焦
    focus() {
      this.template = "security"
      this.urlfooter = this.channel + '.hd.live' || '1.hd.live'
      this.destoryEZU()
      this.getToken()
    },
    //回放
    changePlayback() {
      this.template = 'pcRec'
      this.urlfooter = this.channel + '.rec' || '1.rec'
      if (!this.playerBack) {
        this.getToken()
      } else {
        this.destoryEZU()
      }


    },
    destoryEZU() {
      if (this.player) {
        this.player.stop();
        this.player = null
      }
      let id = `yinshiVideoId${this.index}`
      document.getElementById(id).innerHTML = ''
      let wapId = `yinshiVideoId${this.index}back-wrap`
      let wap = document.getElementById(wapId)
      if (wap) {
        // 有回放的页面
        if (this.template !== 'pcRec') {
          wap.style.display = 'none'
          this.playerBack.stop()
        } else {
          wap.style.display = 'block'
          this.playerBack.play()
        }
      }
    }
  },
  destroyed() {
    if (this.player) {
      this.player.stop();
    }
  }
}
</script>

<style scoped lang="less">
.btn {
  width: 20%;
  background: rgba(46, 50, 60, 0.7);
  position: absolute;
  top: 13%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 22;
}

.video-mian {
  width: 95%;
  height: 98%;
  margin: 0 auto;
}

.video-box {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>