<template>
  <div class="videoItem">
    <video :ref="`videoElement${index}`" :src="videoUrl" :id="`ziyouVideoId${index}`" controls muted
           style="width: 100%; height: 100%; object-fit: fill"></video>
    <div class="video-img" v-show="!isShow" @click="starVideo()">
      <img :src="`${videoUrl}?..getsnap=jpg`" />
      <i class="video-icon el-icon-video-play"></i>
    </div>
  </div>
</template>

<script>
import hlsjs from "hls.js";
export default {
  name: "ZiyouVideo",
  props:['index', 'url', 'width','height'],
  data(){
    return{
      videoUrl:'',
      isShow: false,
      video: null
    }
  },
  mounted() {
    if (this.url.indexOf("http://") == 0) {
      // 设备以HTTP开头也是自有平台的数据流，两种方式均可使用
      this.videoUrl = this.url;
    }else {
      // 判断视频设备是否以kd20开头（自有的平台的视频流规律） 需要对视频的字段进行拼接
      this.videoUrl = `http://171.214.11.22:16880/${this.url}`;
    }
  },
  methods:{
    starVideo(){
      this.isShow = true
      this.show(this.videoUrl);
    },
    show(videoUrl) {
      if (this.video) {
        // 视频暂停的时候
        this.video.play();
        return
      }
      this.video = document.getElementById(`ziyouVideoId${this.index}`); //定义挂载点
      if (hlsjs.isSupported()) {
        this.hlsjs = new hlsjs();
        this.hlsjs.loadSource(videoUrl); //设置播放路径
        this.hlsjs.attachMedia(this.video); //解析到video标签上
        console.log(this.hlsjs);
        this.hlsjs.on(hlsjs.Events.MANIFEST_PARSED, () => {
          this.video.play();
          console.log("加载成功");
        });
        this.hlsjs.on(hlsjs.Events.ERROR, (event, data) => {
          //   console.log(event, data);
          // 监听出错事件
          console.log("加载失败");
        });
        let _this = this
        this.video.addEventListener('pause', function () {
          // console.log('我暂停了')
          _this.isShow = false
          _this.$forceUpdate()
        })
      } else {
        this.$message.error("不支持的格式");
      }
    },
  }
}
</script>

<style scoped lang="less">
.videoItem {
  position: relative;
  height: 100%;
  width: 100%;

  .video-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;

    .video-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      font-size: 70px;
      cursor: pointer;
    }
  }
}

</style>