<template>
  <div class="bowa-video-item">
    <video v-if="playUrl" autoplay controls muted="false" :src="playUrl"
           :height="height-100" :width="width" :id="`bowaVideoId${index}`"></video>
  </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.min.js';
import screen from "../../api/modules/screen";

export default {
  name: "BowaVideo",
  props: ['index', 'url', 'width', 'height'],
  data() {
    return {
      playUrl: '',
      flvPlayers: null
    }
  },
  mounted() {
    this.getVideoUrl()
  },
  methods: {
     getVideoUrl(){
      // const loginData =  await common.bowaLogin()
      screen.getbowaUrl({deviceNo: this.url}).then(res =>{
        const streamUrl = JSON.parse(res.data.streamUrl)
        const StreamMap = streamUrl.StreamMap || null
        const SessionId = JSON.parse(res.data.token).SessionId || null
        if(StreamMap){
          let firstKey = Object.keys(StreamMap)[0]
          for (const key in StreamMap) {
            if (!key.startsWith("_") && key == firstKey) {
              this.playUrl = `${StreamMap[key].Flvurl}?Token=${SessionId}&DeviceNo=${this.url}`;
            }
          }
          this.$nextTick(() => {
            this.createVideo();
          });
        }
      })
    },
    createVideo() {
      if (flvjs.isSupported()) {
        console.log('this.playUrl', this.playUrl)
        let videoElement = document.getElementById(`bowaVideoId${this.index}`);
        let flvPlayer = flvjs.createPlayer({
          type: "flv",
          url: this.playUrl,
          hasAudio: false,
          volume: 0
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();
        this.flvPlayers = flvPlayer;

        this.flvPlayers.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {

          console.log("视频错误信息回调")
          console.log("errorType:", errorType);
          console.log("errorDetail:", errorDetail);
          console.log("errorInfo:", errorInfo);
          flvPlayer.unload();
          flvPlayer.load();
          flvPlayer.play();
        })
      }
    },

  },
  destroyed() {
    if (this.flvPlayers) {
      this.flvPlayers.off(flvjs.Events.ERROR)
      this.flvPlayers.destroy();
      this.flvPlayers = null
    }

  }
}
</script>

<style scoped lang="less">


</style>